<template>
<div>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
        
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
 
      <b-avatar
        size="40"
        :src="userName.profile_image"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <!-- <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        /> -->
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'user-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{userName.first_name}}    {{userName.last_name}}</span>
    </b-dropdown-item> 
    <b-dropdown-item
      :to="{ name: 'conversations-list'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="FileIcon"
        class="mr-50"
      />
      <span>المحادثات</span>
    </b-dropdown-item> 
    <b-dropdown-divider />
    <b-dropdown-item
      :to="{ name: 'leaves-approve'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>طلبات التكليف</span>
    </b-dropdown-item> 

    <b-dropdown-divider />
    <b-dropdown-item
      :to="{ name: 'add-my-problem'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="Edit3Icon"
        class="mr-50"
      />
      <span>تقديم شكوى</span>
    </b-dropdown-item> 
   


    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>تسجيل الخروج</span>
    </b-dropdown-item> 
    </b-nav-item-dropdown>
    
    </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },

  data() {
    return {
      userName:'',
     
      avatarText,
    }
  },

  created(){
    this.$http.get('/api/v1/profile').then(res => {
      // console.log(res.data.data)

      this.userName = res.data.data


    })
  },

  methods: {
    logout() {
       
     
          localStorage.removeItem("token");
           localStorage.removeItem("serverIp");
          // localStorage.removeItem("role");
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability

      this.$ability.update(initialAbility)


      // Redirect to login page
  this.$router.push({ name: 'auth-login' })
    },
  }
};
</script>

